import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';

import {
  Area,
  Bar,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import Loader from '../../../Loaders/ComponentLoader';

import ChartTooltip from './Tooltip';
import { chartColor } from '../../../../styles/muiTheme';
import { generateTicks } from '../../../../helpers/chart-data';
import { unixTimestampToString } from '../../../../helpers/date';

function ProductionProfileChart({
  chartData,
  meters,
  inverters,
  range,
  timezone,
  unit,
  loading,
}) {
  const theme = useTheme();

  if (loading)
    return (
      <div style={{ height: '450px' }}>
        <Loader height={150} width={150} />
      </div>
    );

  if (isEmpty(chartData))
    return (
      <div
        style={{
          height: '450px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Typography>Click "Generate" to fetch chart data</Typography>
      </div>
    );

  const ticks = generateTicks(range);
  return (
    <ResponsiveContainer
      key='production-profile-chart'
      width='100%'
      height={450}>
      <ComposedChart
        width='100%'
        height={450}
        data={chartData}
        margin={{
          top: 5,
          right: 5,
          left: 5,
          bottom: 5,
        }}>
        <YAxis
          mirror
          width={75}
          domain={[0, (dataMax) => Math.ceil(dataMax / 10) * 10]}
          tickFormatter={(val, _axis) => {
            if (val <= 0) return '';
            return (
              numeral(Math.round(val).toPrecision(4)).format('0,0.[000]') +
              ' kW'
            );
          }}
        />
        <XAxis
          type='number'
          dataKey='timestamp'
          tickMargin={10}
          domain={[range.start.unix(), range.end.unix()]}
          ticks={ticks}
          tickFormatter={(unixTime) => {
            let format = 'MM/DD h:mm A';
            if (range.end.unix() - range.start.unix() > 90000) {
              format = 'MM/DD';
            }
            return unixTimestampToString(unixTime, timezone.offset, format);
          }}
        />
        <Tooltip
          content={
            <ChartTooltip
              timezone={timezone}
              meters={meters}
              inverters={inverters}
              unit={unit}
            />
          }
        />
        {map(sortBy(meters, 'parent_index'), (meter, idx) => {
          return (
            <Bar
              stackId='a'
              barSize={10}
              key={`bar-${meter.meter_id}`}
              type='monotone'
              dataKey={meter.meter_id}
              fill={chartColor(idx)}
            />
          );
        })}
        {map(sortBy(inverters, 'parent_index'), (inverter) => {
          return (
            <Area
              dot={false}
              stackId='area'
              key={`line-${inverter.inverter_id}`}
              dataKey={inverter.inverter_id}
              stroke={theme.palette.veregy_colors.orange}
              fill={'transparent'}
              connectNulls
            />
          );
        })}
      </ComposedChart>
    </ResponsiveContainer>
  );
}

ProductionProfileChart.propTypes = {
  chartData: PropTypes.array,
  meters: PropTypes.array,
  inverters: PropTypes.array,
  sites: PropTypes.array,
  range: PropTypes.object,
  timezone: PropTypes.object,
  unit: PropTypes.string,
  loading: PropTypes.bool,
};

export default ProductionProfileChart;
