import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import useTheme from '@mui/material/styles/useTheme';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export const UNITS = ['W', 'kW', 'MW', 'Wh', 'kWh', 'MWh'];

function SelectUnit(props) {
  const { selectedUnit, setSelectedUnit, units } = props;
  const theme = useTheme();

  const [unit, setUnit] = useState('');

  useEffect(() => {
    setUnit(selectedUnit);
  }, [selectedUnit]);

  const handleSelect = (e) => {
    e.preventDefault();
    setSelectedUnit(e.target.value);
  };

  return (
    <FormControl variant='standard'>
      <InputLabel
        id='timezone-label'
        sx={{ textAlign: 'center', width: '5rem' }}>
        Unit
      </InputLabel>
      <Select
        disableUnderline
        labelId='timezone-label'
        value={unit}
        renderValue={() => (
          <ListItemText
            sx={{ color: theme.palette.primary.main, my: 0 }}
            primary={unit}
            primaryTypographyProps={{ align: 'center' }}
          />
        )}
        onChange={handleSelect}
        IconComponent={() => null}
        inputProps={{
          sx: {
            padding: '0 !important',
            color: theme.palette.primary.main,
            ...theme.typography.body2,
          },
        }}
        MenuProps={{
          MenuListProps: { disablePadding: true },
          slotProps: {
            paper: { sx: { minWidth: 'unset !important' } },
          },
        }}
        sx={{
          minWidth: '3rem',
          backgroundColor: 'unset',
          '& .MuiSelect-select': {
            display: 'flex',
            alignItems: 'center',
          },
        }}>
        {units.map((unit) => {
          return (
            <MenuItem
              key={unit}
              value={unit}
              sx={{
                color: theme.palette.primary.main,
                ...theme.typography.body2,
              }}>
              {unit}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

SelectUnit.propTypes = {
  selectedUnit: PropTypes.string,
  setSelectedUnit: PropTypes.func,
};

export default SelectUnit;
