import dayjs from 'dayjs';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const FORMATS = [
  'M/D/YYYY H:mm',
  'MM/DD/YY HH:mm', // Format: %m/%d/%y %H:%M
  'MM/DD/YYYY HH:mm', // Format: %m/%d/%Y %H:%M
  'YYYY/MM/DD hh:mm:ss A', // Format: %Y/%m/%d %I:%M:%S %p
];

const applyMutations = ({ date, mutations = [], offset = null } = {}) => {
  let result = date || dayjs();
  const offsetInMinutes = (offset !== null ? offset : 0) * 60;
  if (offset !== null) result = result.utcOffset(offsetInMinutes);

  for (let mutation of mutations) {
    result = mutation(result);
  }
  return result;
};

function lastMonth(offset) {
  let start = applyMutations({
    mutations: [(d) => d.subtract(1, 'month'), (d) => d.startOf('month')],
    offset,
  });
  let end = applyMutations({
    mutations: [(d) => d.subtract(1, 'month'), (d) => d.endOf('month')],
    offset,
  });

  return { start, end };
}

function thisMonth(offset) {
  let start = applyMutations({
    mutations: [(d) => d.startOf('month')],
    offset,
  });
  let end = applyMutations({
    mutations: [(d) => d.endOf('month')],
    offset,
  });
  return { start, end };
}

function lastWeek(offset) {
  let start = applyMutations({
    mutations: [(d) => d.subtract(1, 'week'), (d) => d.startOf('week')],
    offset,
  });
  let end = applyMutations({
    mutations: [(d) => d.subtract(1, 'week'), (d) => d.endOf('week')],
    offset,
  });

  return { start, end };
}

function thisWeek(offset) {
  let start = applyMutations({
    mutations: [(d) => d.startOf('week')],
    offset,
  });
  let end = applyMutations({
    mutations: [(d) => d.endOf('week')],
    offset,
  });
  return { start, end };
}

function yesterday(offset) {
  let start = applyMutations({
    mutations: [(d) => d.subtract(1, 'day'), (d) => d.startOf('day')],
    offset,
  });
  let end = applyMutations({
    mutations: [(d) => d.subtract(1, 'day'), (d) => d.endOf('day')],
    offset,
  });
  return { start, end };
}

function today(offset) {
  let start = applyMutations({ mutations: [(d) => d.startOf('day')], offset });
  let end = applyMutations({ mutations: [(d) => d.endOf('day')], offset });

  return { start, end };
}

function current(offset) {
  const start = applyMutations({
    mutations: [(d) => d.subtract(1, 'day'), (d) => d.startOf('day')],
    offset,
  });
  const end = applyMutations({
    mutations: [(d) => d.add(1, 'day'), (d) => d.endOf('day')],
    offset,
  });

  return { start, end };
}

function selectDay(day, attr, offset) {
  if (attr === 'start') {
    return applyMutations({
      date: day,
      mutations: [(d) => d.startOf('day')],
      offset,
    });
  } else {
    return applyMutations({
      date: day,
      mutations: [(d) => d.endOf('day')],
      offset,
    });
  }
}

function getLocalTimzone() {
  const now = dayjs();
  const zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const name = now.tz(zone).format('z');
  const offset = now.tz(zone).utcOffset() / 60;

  return { name, zone, offset };
}

const unixTimestampToString = (
  unixTimestamp,
  offset,
  format = 'MM/DD h:mm A'
) => {
  return dayjs
    .unix(unixTimestamp)
    .utcOffset(offset * 60)
    .format(format);
};

/**
 * Function to detect format, convert to Unix time, and apply timezone offset
 * @param {string} timestamp - The date string to parse
 * @param {number} timezoneOffset - The timezone offset in hours (e.g., -5 for EST)
 * @returns {number | null} - Unix timestamp (in seconds) or null if no format matches
 */
function convertStringToUnix(timestamp, timezoneOffset = 0) {
  let parsedDate = null;

  if (typeof timestamp !== 'string' || timestamp === '') {
    return null;
  }

  for (const format of FORMATS) {
    const date = dayjs(timestamp, format, true); // `true` ensures strict parsing
    if (date.isValid()) {
      parsedDate = date;
      break;
    }
  }

  if (parsedDate) {
    // Adjust the timezone offset (if provided) and convert to Unix timestamp
    let adjustedDate = parsedDate.add(timezoneOffset, 'hour');
    adjustedDate = adjustedDate
      .minute(adjustedDate.minute() - (adjustedDate.minute() % 15))
      .second(0)
      .millisecond(0);
    return adjustedDate.unix(); // Return Unix time (in seconds)
  } else {
    throw new Error(`Invalid format : ${timestamp}`);
  }
}

/**
 * Convert Unix timestamp to a timezone-aware dayjs object
 * @param {number} timestampInSeconds - Unix timestamp in seconds
 * @param {string} tz - Timezone string (e.g., 'America/New_York', 'Europe/London')
 * @returns {dayjs.Dayjs} - A dayjs object aware of the specified timezone
 */
function convertToTimezoneAwareDayjs(timestampInSeconds, tz) {
  return dayjs.unix(timestampInSeconds).tz(tz);
}

const getLatestInterval = () => {
  let now = dayjs();
  return now
    .minute(now.minute() - (now.minute() % 15))
    .second(0)
    .millisecond(0);
};

const dateStringToDayJS = (dateString) => {
  return dayjs(dateString?.substring(0, 19) + dateString?.slice(-6));
};

export {
  applyMutations,
  current,
  today,
  yesterday,
  lastWeek,
  thisMonth,
  lastMonth,
  thisWeek,
  selectDay,
  getLocalTimzone,
  unixTimestampToString,
  convertStringToUnix,
  convertToTimezoneAwareDayjs,
  getLatestInterval,
  dateStringToDayJS,
};
